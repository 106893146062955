import http from "../http-common";

class UsersDataService {
  getAll() {
    return http.get("/users");
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/auth/signup", data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

login(data) {
    return http.post("/auth/login/password", data)
        .then(response => response.data)
        .catch(error => {
            if (error.response) {
                console.error("Error response from server:", error.response.data);
                console.error("Status Code:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
            throw error;
        });
}


  logOut() {
    return http.post("/auth/logout");
  }

  findByTitle(category) {
    return http.get(`/users?category=${category}`);
  }
}

export default new UsersDataService();
